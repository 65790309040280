<template>
    <uw-content title="开票记录" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.workflow_state == null ? 'primary' : 'text'" @click="search.workflow_state = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.workflow_state == 0 ? 'primary' : 'text'" @click="search.workflow_state = 0; TableSearch()">未审核</el-button>
            <el-button size="mini" :type="search.workflow_state == 1 ? 'primary' : 'text'" @click="search.workflow_state = 1; TableSearch()">待审核</el-button>
            <el-button size="mini" :type="search.workflow_state == 2 ? 'primary' : 'text'" @click="search.workflow_state = 2; TableSearch()">审核中</el-button>
            <el-button size="mini" :type="search.workflow_state == 3 ? 'primary' : 'text'" @click="search.workflow_state = 3; TableSearch()">已拒绝</el-button>
            <el-button size="mini" :type="search.workflow_state == 4 ? 'primary' : 'text'" @click="search.workflow_state = 4; TableSearch()">已通过</el-button>
            <el-button size="mini" :type="search.workflow_state == 5 ? 'warning' : 'text'" @click="search.workflow_state = 5; TableSearch()">待撤销</el-button>
            <el-button size="mini" :type="search.workflow_state == 6 ? 'warning' : 'text'" @click="search.workflow_state = 6; TableSearch()">撤销中</el-button>
            <el-button size="mini" :type="search.workflow_state == 7 ? 'warning' : 'text'" @click="search.workflow_state = 7; TableSearch()">撤销失败</el-button>
            <el-button size="mini" :type="search.workflow_state == 8 ? 'warning' : 'text'" @click="search.workflow_state = 8; TableSearch()">已撤销</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">新建开票记录</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">

                <crm-contract-select v-model="search.contract_id" @onChange="TableSearch()" />

                <!-- 回款状态 -->
                <el-select v-model="search.pay_state" @change="TableSearch()" placeholder="回款状态" size="mini" clearable filterable>
                    <el-option label="已退款" :value="3"></el-option>
                    <el-option label="未退款" :value="2"></el-option>
                    <el-option label="已回款" :value="1"></el-option>
                    <el-option label="未回款" :value="0"></el-option>
                </el-select>

                <config-invoice-type-select v-model="search.invoice_type_id" @onChange="TableSearch()" />

                <!-- 回款日期 -->
                <el-select v-model="search.invoice_date" @change="TableSearch()" placeholder="开票日期" size="mini" clearable filterable>
                    <el-option label="7天内" value="-7 day"></el-option>
                    <el-option label="15天内" value="-15 day"></el-option>
                    <el-option label="30天内" value="-30 day"></el-option>
                    <el-option label="60天内" value="-60 day"></el-option>
                    <el-option label="60天后" value="+60 day"></el-option>
                </el-select>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    show-footer
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :footer-method="TableFooterMethod"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 是否回款 -->
                    <vxe-column width="70" field="pay_state" title="是否回款" align="center">
                        <template #default="{ row }">
                            <el-link :underline="false" v-if="row.pay_state == 0" type="warning">未回款</el-link>
                            <el-link :underline="false" v-if="row.pay_state == 1" type="success">已回款</el-link>
                            <el-link :underline="false" v-if="row.pay_state == 2" type="danger">未退款</el-link>
                            <el-link :underline="false" v-if="row.pay_state == 3" type="primary">已退款</el-link>
                        </template>
                    </vxe-column>

                    <!-- 审批判断 -->
                    <vxe-column width="90" field="invoice_content" title="审批状态" align="center">
                        <template #default="{ row }">
                            <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">未审核</el-tag>
                            <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                            <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                            <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                            <el-tag v-if="row.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                            <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                            <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                            <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">撤销失败</el-tag>
                            <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="danger">已撤销</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 开票内容 -->
                    <vxe-column width="140" field="invoice_content" title="开票内容" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerDetails.Open(row.id)">{{ row.invoice_content }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 票据类型 -->
                    <vxe-column width="120" field="invoice_type_id" title="票据类型" sortable>
                        <template #default="{ row }">
                            {{ row.invoice_type.name }}
                        </template>
                    </vxe-column>

                    <!-- 票据金额 -->
                    <vxe-column min-width="120" field="invoice_money" title="票据金额" sortable>
                        <template #default="{ row }">
                            ￥{{ row.invoice_money.toLocaleString() }}
                        </template>
                    </vxe-column>

                    <!-- 是否含税 -->
                    <vxe-column min-width="90" field="taxation_state" title="是否含税" sortable>
                        <template #default="{ row }">
                            <el-link v-if="row.taxation_state == 0">不含税</el-link>
                            <el-link v-if="row.taxation_state == 1">含税</el-link>
                        </template>
                    </vxe-column>

                    <!-- 税率 -->
                    <vxe-column width="90" field="taxation_rate" title="税率" sortable>
                        <template #default="{ row }">
                            {{ row.taxation_rate }}%
                        </template>
                    </vxe-column>

                    <!-- 税金 -->
                    <vxe-column min-width="120" field="taxation_money" title="税金" sortable>
                        <template #default="{ row }">
                            ￥{{ row.taxation_money.toLocaleString() }}
                        </template>
                    </vxe-column>

                    <!-- 开票日期 -->
                    <vxe-column width="100" field="invoice_date" title="开票日期" sortable></vxe-column>

                    <!-- 经办人 -->
                    <vxe-column width="80" field="user.name" title="经办人"></vxe-column>

                    <!-- 对应合同 -->
                    <vxe-column min-width="250" field="contract.name" title="对应合同">
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerContractDetails.Open(row.contract.id)">
                                {{ row.contract.number }} - {{ row.contract.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 对应客户 -->
                    <vxe-column min-width="250" field="customer.name" title="对应客户">
                        <template #default="{ row }">
                            <el-link type="primary">
                                {{ row.customer.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 创建者 -->
                    <vxe-column width="80" field="created_user.name" title="创建者"></vxe-column>

                    <!-- 更新者 -->
                    <vxe-column width="80" field="updated_user.name" title="更新者"></vxe-column>

                    <vxe-column width="140" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-link @click="$refs.drawerUpdate.Open(row.id)" icon="el-icon-edit-outline" type="primary" :underline="false">编辑</el-link>
                            <el-link @click="TableDelete(row.id)" icon="el-icon-delete" type="danger" :underline="false" class="w-margin-l-10">删除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
        </uw-table>

        <!-- 组件 -->
        <contract-pay-invoice-create ref="drawerCreate" @success="TableSearch" />
        <contract-pay-invoice-update ref="drawerUpdate" @success="TableSearch" />
        <contract-pay-invoice-details ref="drawerDetails" @onChange="TableSearch" />
        <crm-contract-drawer ref="drawerContractDetails" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {

            loading: false,
            
            table: [],
            statistics: {},

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                contract_id: null,
                invoice_type_id: null,
                pay_state: null,
                invoice_date: null,
                workflow_state: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/my/contract-pay-invoice',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    statistics: true,
                    append: ['invoice_type', 'user', 'customer', 'contract', 'created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body
                this.statistics = rps.data.statistics

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooterMethod() {
            return [
                ['本页',,,,, '￥' + this.statistics.page?.invoice_money.toLocaleString(),,, '￥' + this.statistics.page?.taxation_money.toLocaleString()],
                ['合计',,,,, '￥' + this.statistics.all?.invoice_money.toLocaleString(),,, '￥' + this.statistics.all?.taxation_money.toLocaleString()]
            ]
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.loading = true
            this.$http.post('/9api/contract-pay-invoice/delete', { id: id }).then((rps) => {
                this.TableSearch()
            })
        },
    }
}
</script>